



























































































import DagsQuery from '@/services/Databus/application/query/DagsQuery'
import DagRunCommand from '@/services/Databus/application/command/DagRunCommand'
import Dag, { DagDTO } from '@/services/Databus/domain/model/Dag/Dag'

export default {
  name: 'DagPanel',
  components: {
  },
  data () {
    return {
      loading: false,
      dagsPageSize: 0,
      dagsCount: 0,
      allDagsCount: 0,
      dags: [],
      dag: null
    }
  },
  inject: ['getQueryBus', 'getCommandBus'],
  methods: {
    async loadDags(callback) {
      await this.getQueryBus().execute(
        new DagsQuery({})
      ).then(data => {
        this.dags = data;
        this.dagsPageSize = data.length;
        this.dagsCount = data.length;
      });
    },
    runDug(record) {
      let dag = Dag.create(record);
      this.getCommandBus().execute(
        new DagRunCommand(
          dag.getId()
        )
      ).then(() => {
        this.loadDags();
      });
    },
    changeDag(dagDto) {
      if (dagDto !== null) {
        this.dag = Dag.create(dagDto);
      } else {
        this.dag = null;
      }
    }
  },
  mounted () {
    this.loadDags();
  }
}
